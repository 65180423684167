
//== Variables

@import 'site/variables';
@import 'components/icons/_icons.scss';
@import 'site/mixins';
@import 'site/functions';
@import 'site/grid';

//== Site global

@import 'npm:normalize.css';
@import 'site/fonts';
@import 'site/base';

//== Core components

@import 'components/text/_text.scss';

@import 'components/title/_title.scss';
@import 'components/title/_title_styles.scss';

@import 'components/image/_image.scss';
@import 'components/image/_image_caption.scss';
@import 'components/image/_image_standard.scss';

@import 'components/button/button';

@import 'components/separator/_separator.scss';

@import 'components/progressbar/_progressbar.scss';

@import 'components/download/_download.scss';

//== Lists and Navigation components

@import 'components/list/_list.scss';

@import 'components/navigation/_navigation.scss';

@import 'components/languagenavigation/_languagenavigation.scss';

@import 'components/breadcrumb/_breadcrumb.scss';
@import 'components/breadcrumb/_breadcrumb_standard.scss';

@import 'components/search/_search.scss';

//== Container components

@import 'components/container/_container.scss';
@import 'components/container/_container_content.scss';
@import 'components/container/_container_dark_theme.scss';
@import 'components/container/_container_main.scss';
@import 'components/container/_container_teaser_two_up.scss';
@import 'components/container/_container_social_icons.scss';
@import 'components/container/_container_teaser_three_up.scss';
@import 'components/container/_container_teaser_four_up.scss';
@import 'components/container/_container_banner_bar.scss';
@import 'components/container/_container_product_information.scss';
@import 'components/container/_container_player_information.scss';

@import 'components/carousel/_carousel.scss';

@import 'components/tabs/_tabs.scss';
@import 'components/tabs/_tabs_horizontal.scss';
@import 'components/tabs/_tabs_vertical.scss';
@import 'components/tabs/tabs_faq';

@import 'components/accordion/_accordion.scss';

//== Fragments (CF + XF) components

@import 'components/contentfragment/_contentfragment.scss';
@import 'components/contentfragmentlist/_contentfragmentlist.scss';

//== Form components

@import 'components/form/_form.scss';
@import 'components/form-button/_form-button.scss';
@import 'components/form-text/_form-text.scss';
@import 'components/form-options/_form-options.scss';

//== Components

@import 'components/header/_header.scss';

@import 'components/footer/_footer.scss';

@import 'components/embed/_embed.scss';
@import 'components/embed/_embed-third-party-experience.scss';

@import 'components/pdfviewer/_pdfviewer.scss';

@import 'components/socialmediasharing/_social_media_sharing.scss';

@import 'components/teaser/_teaser_full_bleed.scss';

@import 'components/teaser/_teaser_content.scss';

//== Nurtritional Information
@import 'components/nutritionalinformation/_nutritionalinformation.scss';

