.cmp-tabs {
  margin: 0.5em;
}

.cmp-tabs__tablist {
}
.cmp-tabs__tab {
  height: 2.625em;
  margin: 0;
  padding: 0.5625em 1em 0.6875em 1em;
  color: $color-shade-3;
  font-size: 1em;
  outline: none;
  &:hover {
    color: $color-text;
  }
  //&:focus {
  //  border-radius: 0.25em;
  //  border: 0.125em solid $color-accent-lighter;
  //}
}
.cmp-tabs__tab--active {
  color: $color-text;
  border: none;
  &:focus {
    //border-radius: 0;
    border: none;
  }
}

.cmp-tabs__tabpanel {
}
.cmp-tabs__tabpanel--active {
  padding-right: 0.5em;
  padding-left: 0.5em;
}