.social-icons {
  @include socialIcons();
  .social-icon {
    @include socialIcon(true);
    a {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
