.header {
  background: $color-background-black;
  padding: 0 spacing(5);
  height: rem-calc(100);
  display: flex;
  align-items: center;

  @include breakpoint-down(tablet) {
    height: 100%;
  }

  .cmp-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @include breakpoint-down(tablet) {
      flex-wrap: wrap;
    }

    > :nth-child(2) {
      margin: auto;
    }
  }

  &__hamburger-button {
    cursor: pointer;
    background: none;
    display: none;
    border: none;
    padding: 0;
    margin: spacing(.5) spacing(2) 0 spacing(.5);

    @include breakpoint-down(tablet) {
      display: block;
    }
  }

  &__hamburger-bar {
    width: rem-calc(20);
    height: rem-calc(3);
    background-color: $color-background-white;
    margin: spacing(.5) 0;
    transition: 0.4s;
    border-radius: rem-calc(5);
  }

  &__image {
    width: inherit;
    max-width: rem-calc(200);
    margin-top: 0;

    img {
      margin-top: 0;
      width: rem-calc(200);
    }
  }

  &__navigation {
    width: 100%;
    margin-left: spacing(4);

    @include breakpoint-down(tablet) {
      margin-left: 0;
      display: none;
    }

    &-visible {
      @include breakpoint-down(tablet) {
        display: block;
      }
    }

    .cmp-text {
      margin: 0;
      overflow-x: visible;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      @include breakpoint-down(tablet) {
        flex-direction: column;
      }
    }

    li {
      height: spacing(12);
      list-style-type: none;
      position: relative;
      list-style: none;
      display: flex;
      align-items: center;
      position: relative;
      margin-right: spacing(1);
      color: $color-text-secondary;
      padding-right: spacing(1);

      @include breakpoint-down(tablet) {
        height: auto;
        border-bottom: rem-calc(1) solid $color-border-grey;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding: spacing(2) spacing(1) spacing(2) spacing(.5);

        &:last-of-type {
          border-bottom: none;
        }

        &:hover {
          margin-bottom: 0;
          border-bottom: rem-calc(1) solid $color-border-grey;
          &:last-of-type {
            border-bottom: none;
          }
        }

        &.has-ul {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
        }
      }

      &:hover {
        color: $color-border-blue;
        margin-bottom: rem-calc(-3);
        border-bottom: rem-calc(4) solid $color-border-blue;
        cursor: pointer;

        &.has-ul::after,
        &.has-ul::before {
          transform: rotate(90deg);
        }

        &.has-ul {
          border-bottom-color: $color-border-black;
          padding-bottom: 0;
        }

        @include breakpoint-down(tablet) {
          margin-bottom: 0;
          border-bottom: rem-calc(1) solid $color-border-grey;
          &.has-ul {
            border-bottom-color: $color-border-grey;
            margin-bottom: 0;
            padding-bottom: spacing(2);
          }
          &.has-ul::after,
          &.has-ul::before {
            transform: none;
          }
        }
      }

      a {
        display: block;
        color: $color-text-grey;
        text-decoration: none;
      }

      // Expandable triangle
      &.has-ul::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem-calc(3) 0 rem-calc(3) rem-calc(5);
        border-color: transparent transparent transparent $color-border-blue;
        margin-right: rem-calc(10);
        margin-left: rem-calc(5);
        transition: transform 0.3s ease;
        display: block;
        @include breakpoint-down(tablet) {
          display: none;
        }
      }

      &.has-ul::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem-calc(3) 0 rem-calc(3) rem-calc(5);
        border-color: transparent transparent transparent $color-border-blue;
        margin-right: rem-calc(10);
        transition: transform 0.3s ease;
        display: none;
        @include breakpoint-down(tablet) {
          display: block;
        }
      }

      ul {
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease;
      }

      &:hover > ul {
        height: auto;
      }
    }

    // Dropdown Lists
    ul ul {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: spacing(.25);
      left: 0;
      min-width: rem-calc(300);
      background-color: $color-background-black;
      border: rem-calc(1) solid $color-border-grey;

      overflow: visible;

      @include breakpoint-down(tablet) {
        position: relative;
        border: none;
        border-bottom: rem-calc(1) solid $color-border-grey;
        padding-left: 0;
        width: 100%;
      }

      &::before {
        content: '';
        position: absolute;
        top: rem-calc(-9);
        left: rem-calc(30);
        width: spacing(2);
        height: spacing(2);
        border-style: solid;
        border-width: rem-calc(1) 0 0 rem-calc(1);
        border-color: $color-grey;
        transform: rotate(45deg);
        background-color: $color-background-black;
        @include breakpoint-down(tablet) {
          display: none;
        }
      }
    }

    li:hover > ul,
    &.opened {
      display: block;
      @include breakpoint-down(tablet) {
        display: none;
      }
    }

    li.opened {
      border-bottom: none;
      ul {
        display: block;
        height: auto;
      }
      &::before, &::after {
        transform: rotate(90deg);
      }
      &:hover {
        &::before {
          transform: rotate(90deg);
        }
      }
    }

    ul ul li {
      float: none;
      width: 100%;
      height: auto;
      color: $color-grey;
      border-bottom: rem-calc(1) solid $color-grey;
      padding: 0;
      &:last-of-type {
        border-bottom-color: transparent;
        &:hover {
          border-bottom-color: transparent;
        }
      }

      @include breakpoint-down(tablet) {
        border: none;
        margin-left: 0;
        &:hover {
          border-bottom: none;
          color: $color-grey;
        }
      }
    }

    ul ul a {
      padding: spacing(2);
      color: white;
      display: block;
      text-align: left;

      &:hover {
        color: $color-border-blue;
        border-bottom: none;
      }

      @include breakpoint-down(tablet) {
        padding-left: 0;
      }
    }

    ul ul ul {
      top: 0;
      left: 100%;
    }
  }
}
