.cmp-text {
  margin: 0.75rem 0 0 0;
  color: $color-text;
  font-weight: 200;
  font-size: 1em;
  font-weight: 200;
  @include bodyFont();

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Druk-SuperItalic';
  }
  & b {
    font-weight: bold;
  }
  & p a {
    color: $color-accent;
    text-decoration: none;
    border: 0.125em solid $color-transparent;
    border-radius: 0.25em;
    &:hover {
      color: $color-accent-darker;
      text-decoration: underline;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $color-accent-darker;
      text-decoration-style: solid;
    }
    &:focus {
      text-decoration: underline;
      border-color: $color-accent;
      outline: none;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $color-accent;
      text-decoration-style: solid;
    }
  }
  & blockquote {
    margin: 0.75rem 0;
    font-size: 1.75em;
    font-family: titleFont, serif;
    font-style: italic;
    line-height: 2.375rem;
    margin-inline: 0;
  }
}
.cmp-text__paragraph {
}

.text.stats {
    h1, h2, h3, h4, h5, h6, p {
      display: inline-block;
    }
    p {
      margin-top: 0;
      margin-bottom: spacing(2);
      width: 100%;
    }
}
