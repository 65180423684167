.container.four-up {
  width: 100%;

  .cmp-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1100px;
    gap: 15px;
    padding: 15px;
    @include breakpoint-down(tablet) {
      flex-wrap: wrap;
    }
  }

  .teaser.content {
    flex-basis: calc(33.333% - 10px);
    margin: 0 auto;
  }

  @include breakpoint-down(tablet) {
    .teaser.content {
      flex-basis: calc(50% - 10px);
    }
  }

  @media (max-width: 450px) {
    .teaser.content {
      flex-basis: calc(100% - 10px);
    }
  }
}
