// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
  
    @return if($min !=0, $min, null);
  }
  
  // Maximum breakpoint width.
  // The maximum value is reduced by 0.02px to work around the limitations of
  // `min-` and `max-` prefixes and viewports with fractional widths.
  // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
  // Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
  // See https://bugs.webkit.org/show_bug.cgi?id=178261
  //
  //    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    767.98px
  @function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $max: map-get($breakpoints, $name);
  
    @return if($max and $max > 0, $max - 0.02, null);
  }
  
  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
  // Makes the @content apply to the given breakpoint and wider.
  @mixin breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
  
    @if $min {
      @media screen and (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media of at most the maximum breakpoint width. No query for the largest breakpoint.
  // Makes the @content apply to the given breakpoint and narrower.
  @mixin breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
  
    @if $max {
      @media screen and (max-width: $max) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  @mixin base-button($inverted: false) {
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: rem-calc(16);
    line-height: 150%;
    text-transform: uppercase;
    font-weight: 700;
    min-height: rem-calc(40);
    min-width: rem-calc(279);
    padding: 0 rem-calc(24);
    text-decoration: none;
    border-radius: rem-calc(8);
    border: none;
    cursor: pointer;
  
    &:disabled {
      cursor: default;
    }
  
    @if ($inverted) {
      background-color: $color-background-white;
      color: $color-text-black;
  
      span {
        color: $color-text-black;
      }
  
      &:hover {
        background-color: $color-background-grey-3;
      }
  
      &:disabled {
        background-color: $color-background-grey-6;
        color: $color-text-grey-3;
  
        span {
          color: $color-text-grey-3;
        }
      }
    } @else {
      background-color: $color-background-black;
      color: $color-text-white;
  
      span {
        color: $color-text-white;
      }
  
      &:hover {
        background-color: $color-background-grey-5;
        color: $color-text-white;
      }
  
      &:disabled {
        background-color: $color-background-grey-3;
        color: $color-text-grey-6;
  
        span {
          color: $color-text-grey-6;
        }
      }
    }
  }
  
  @mixin secondary-button($inverted: false) {
    @include base-button();
  
    @if ($inverted) {
      border: rem-calc(2) solid $color-border-white;
      color: $color-text-white;
  
      span {
        color: $color-text-white;
      }
  
      &:hover {
        background-color: $color-background-black;
        color: $color-text-grey-3;
        border-color: $color-border-grey-3;
  
        span {
          color: $color-text-grey-3;
        }
      }
  
      &:disabled {
        background-color: $color-background-black;
        color: $color-text-grey-6;
        border-color: $color-border-grey-6;
  
        span {
          color: $color-text-grey-6;
          border-color: $color-border-grey-6;
        }
      }
    } @else {
      background-color: $color-background-white;
      border: rem-calc(2) solid $color-border-black;
      color: $color-text-black;
  
      span {
        color: $color-text-black;
      }
  
      &:hover {
        background-color: $color-background-white;
        border-color: $color-border-grey-5;
        color: $color-text-grey-5;
  
        span {
          color: $color-text-grey-5;
        }
      }
  
      &:disabled {
        background-color: $color-background-white;
        border-color: $color-border-grey-3;
        color: $color-text-grey-3;
  
        span {
          color: $color-text-grey-3;
        }
      }
    }
  }
  
  @mixin tertiary-button($inverted: false) {
    box-sizing: border-box;
    white-space: nowrap;
    min-width: auto;
    min-height: auto;
    background-color: transparent;
    text-decoration: none;
    display: inline-flex;
    padding: 0;
    font-weight: 400;
    gap: rem-calc(8);
    @include icon($iconArrowRight, 22, 20);
    width: auto;
  
    &:hover,
    &:disabled {
      background-color: transparent;
    }
    @if ($inverted) {
      color: $color-text-white;
  
      span {
        color: $color-text-white;
      }
  
      &:hover {
        color: $color-text-grey-3;
  
        span {
          color: $color-text-grey-3;
        }
      }
  
      &:disabled {
        color: $color-text-grey-6;
  
        span {
          color: $color-text-grey-6;
        }
      }
    } @else {
      color: $color-text-black;
  
      span {
        color: $color-text-black;
      }
  
      &:hover {
        color: $color-text-grey-5;
  
        span {
          color: $color-text-grey-5;
        }
      }
  
      &:disabled {
        color: $color-text-grey-3;
  
        span {
          color: $color-text-grey-3;
        }
      }
    }
  }
  
  @mixin text-link($inverted: false) {
    box-sizing: border-box;
    white-space: nowrap;
    min-width: auto;
    min-height: auto;
    background-color: transparent;
    padding: 0;
    text-decoration: underline;
    font-size: rem-calc(14);
    line-height: rem-calc(14);
    width: auto;
  
    &:hover,
    &:disabled {
      background-color: transparent;
    }
    @if ($inverted) {
      color: $color-text-white;
  
      span {
        color: $color-text-white;
      }
  
      &:hover {
        color: $color-text-grey-3;
  
        span {
          color: $color-text-grey-3;
        }
      }
  
      &:disabled {
        color: $color-text-grey-6;
  
        span {
          color: $color-text-grey-6;
        }
      }
    } @else {
      color: $color-text-black;
  
      span {
        color: $color-text-black;
      }
  
      &:hover {
        color: $color-text-grey-5;
  
        span {
          color: $color-text-grey-5;
        }
      }
  
      &:disabled {
        color: $colors-grey-3;
  
        span {
          color: $colors-grey-3;
        }
      }
    }
  }
  
  @mixin headerStyles() {
    h1,
    h2,
    h3,
    h4 {
      font-family: 'Druk-SuperItalic';
      letter-spacing: 0;
      line-height: 110%;
      text-transform: uppercase;
    }
  
    h1 {
      font-size: rem-calc(86);
  
      @include breakpoint-down(tablet) {
        font-size: rem-calc(45);
      }
    }
  
    h2 {
      font-size: rem-calc(57);
  
      @include breakpoint-down(tablet) {
        font-size: rem-calc(42);
      }
    }
  
    h3 {
      font-size: rem-calc(40);
  
      @include breakpoint-down(tablet) {
        font-size: rem-calc(28);
      }
    }
  
    h4 {
      font-size: rem-calc(24);
  
      @include breakpoint-down(tablet) {
        font-size: rem-calc(20);
      }
    }
  }
  
  @mixin socialIcons() {
    flex-direction: flex-start;
    margin: 0;
    .cmp-container {
      display: flex;
      flex-direction: row;
      justify-content: left;
    }
  }
  
  @mixin socialIcon($inverted: false) {
    button,
    a,
    .cmp-button {
      min-height: rem-calc(40);
      background: if($inverted, $color-background-white, $color-background-black);
      min-width: auto;
      text-decoration: none;
      cursor: pointer;
      border: rem-calc(2) solid if($inverted, $color-border-black, $color-border-white);
      width: rem-calc(40);
      height: rem-calc(40);
    }
    a,
    .cmp-button {
      display: flex;
      height: rem-calc(25);
      border: none;
      justify-content: center;
      align-items: center;
      font-size: rem-calc(16);
      border: rem-calc(2) solid if($inverted, $color-border-black, $color-border-white);
      border-radius: rem-calc(35);
    }
    .cmp-button__icon {
      color: if($inverted, $color-text-primary, $color-text-secondary);
      &:hover {
        color: $color-border-blue;
      }
      &:after {
        font-size: rem-calc(16);
      }
    }
    .cmp-button,
    a {
      margin: rem-calc(5);
      padding: rem-calc(5);
  
      @if $inverted {
        margin-right: rem-calc(20);
      }
    }
    .cmp-button__text {
      display: none;
    }
  }
  