
//== Defaults

html, body {
  margin: 0;
  color: $color-text;
  font-size: #{$font-size}px;
  background: $color-background-white;
  font-weight: 200;
  overflow-x: hidden;
  @include bodyFont();
}

a {
  color: $color-link;
}

p {
  margin-block: 0.75rem;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
  letter-spacing: 0.05rem;
  font-weight: 400;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@include breakpoint-down(tablet) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}

.cmp-experiencefragment--powerade_header { 
  .xf-content-height { 
    min-height: auto; 
  } 
}