.footer {
  background: $color-background-black;
  padding: spacing(9) spacing(20);

  .cmp-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }

  @include breakpoint-down(tablet) {
    padding-left: spacing(7);
    padding-right: spacing(7);
  }

  &__copyright-text {
    width: 100%;
    color: $color-text-secondary;
    margin-top: spacing(3);
    margin-bottom: spacing(3);
    padding-top: spacing(3);
    font-size: rem-calc(12);
    justify-content: flex-end;
    border-top: rem-calc(1) solid $color-border-grey;
    p {
      text-align: right;
      color: $color-text-secondary;
      @include breakpoint-down(tablet) {
        text-align: center;
      }
      a {
        color: $color-text-secondary;
        text-decoration: underline;
        &:hover, &:focus {
          color: $color-border-blue;
          text-decoration-color: $color-border-blue;
        }
      }
    }

  }

  &__image {
    width: 100%;
    border-bottom: rem-calc(1) solid $color-border-grey;
    img {
      width: spacing(25);
      margin-bottom: spacing(6);
    }
  }

  &__social-icons {
    @include socialIcons();
    margin-top: spacing(4.5);
   
    @include breakpoint-down(tablet) {
      width: 100%;
      margin-top: spacing(2);
    }
  }

  &__social-icon {
    @include socialIcon();
  } 

  &__links {
    margin-top: spacing(3);
    width: 65%;
    @include breakpoint-down(tablet) {
      width: 100%;
    }
    .cmp-text {
      a {
        text-decoration: none;
        color: $color-text-secondary;
        &:hover {
          color: $color-border-blue;
        }
      }
      @include breakpoint-down(tablet) {
        padding-bottom: spacing(2);
        margin-bottom: spacing(2);
      }

      ul {
        padding: 0 0 0 spacing(1);
        margin: spacing(2) 0 0 0;
        display: flex;
        flex-direction: row;
        list-style: none;
        @include breakpoint-down(tablet) {
          flex-direction: column;
        }
        li {
          position: relative;
          margin-right: spacing(2.5);
          margin-bottom: spacing(2);
          color: $color-text-grey;
          width: rem-calc(100);
          font-weight: 800;
          font-size: rem-calc(15);
          @include breakpoint-down(tablet) {
            cursor: pointer;
          }
          a {
            color: $color-text-grey;
          }
          ul {
            padding: 0;
            a {
              color: $color-text-grey;
            }
          }
          @include breakpoint-down(tablet) {
            font-weight: 600;
            font-size: rem-calc(20);
            width: 100%;

            &.has-li {
              &.opened {
                &::after {
                  transform: rotate(225deg);
                }
                ul {
                  display: none;
                }
              }

              &::after {
                content: '';
                position: absolute;
                top: rem-calc(5);
                right: rem-calc(5);
                width: 0;
                height: 0;
                width: rem-calc(15);
                height: rem-calc(15);
                border-top: rem-calc(2) solid $color-border-white;
                border-left: rem-calc(2) solid $color-border-white;
                transform: rotate(225deg);
                transition: transform 0.3s ease;
              }

              &.opened {
                &::after {
                  transform: rotate(45deg);
                }
                ul {
                  display: block;
                }
              }
            }
          }

          ul {
            flex-direction: column;
            li {
              font-weight: 400;
              font-size: rem-calc(14);
              &::after {
                display: none;
              }
            }
            @include breakpoint-down(tablet) {
              display: none;
            }
          }
        }
      }
    }
  }
}
