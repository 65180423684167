.container {
  &.centered-content {
    width: 100%;
    padding: 0 spacing(2);

    .cmp-container {
      max-width: 1120px;
      margin: 0 auto;

      @include breakpoint-down(tablet) {
        max-width: 640px;
      }
    }
  }
}
