.container.two-up {
  width: 100%;

  & > .cmp-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: rem-calc(1100);
    gap: spacing(2);
    padding: spacing(2);
    & > .container {
      flex-basis: calc(50% - rem-calc(10));
      margin: 0 auto;
    }
    @include breakpoint-down(tablet) {
      & > .container {
        flex-basis: calc(100% - rem-calc(10));
      }
    }
  }
}
