.cmp-image {
  img {
    height: fit-content;
  }
}
.cmp-image__link {
}
.cmp-image__image {
  display: block;
}
.cmp-image__title {
}
