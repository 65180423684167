.title {
  &.text-center {
    .cmp-title__text {
      text-align: center;
    }
  }
  &.text-right {
    .cmp-title__text {
      text-align: right;
    }
  }

  .cmp-title{ 
    @include headerStyles();
    margin-top: 2.5rem;
    margin-bottom: 0.75rem;
  }
}
