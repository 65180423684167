.teaser.content {

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06); 
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;

  &.headline-align-right .cmp-teaser__title {
    text-align: right !important;
  }

  &.headline-align-center .cmp-teaser__title {
    text-align: center !important;
  }

  &.cta-style-button .cmp-teaser__action-link {
    @include base-button();
    min-width: 0;
    width: 100%;
    &::after {
      display: none;
    }
  }

  &.cta-style-button .cmp-teaser__action-container {
    padding-bottom: spacing(2);
    width: calc(100% - spacing(3.5));;
    bottom: spacing(0);
  }

  &.cta-style-button {
    padding-bottom: spacing(1);
  }

  .cmp-teaser {
    display: flex;
    flex-direction: column-reverse;

    &__content {
      padding: 15px 25px 50px 15px;
    }

    &__description {
      margin-bottom: 15px; 
      line-height: 10px;
    }

    &__action-container {
      position: absolute;
      bottom: 0;
      padding-bottom: 25px;
      margin-top: 45px;
    }

    &__title {
      font-family: 'Druk-SuperItalic';
      font-size: rem-calc(40);
      line-height: 110%;

      @include breakpoint-down(md) {
        font-size: rem-calc(28);
      }

      a {
        font-size: rem-calc(40);
        color: $color-text-primary;
        text-decoration: none;
        @include breakpoint-down(md) {
          font-size: rem-calc(28);
        }
      }
    }

    &__action-link {
      @include tertiary-button();
    }
  }
}
