.container.player-information {
  > .cmp-container {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      border-radius: rem-calc(7);
    }
  }
}
