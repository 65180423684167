.container.banner-bar {
  .cmp-container {
    background-color: $color-background-black;
    border-radius: rem-calc(8);
    padding: rem-calc(40) rem-calc(24);
    text-align: center;
    box-sizing: border-box;

    @include breakpoint-up(tablet) {
      padding: rem-calc(48) rem-calc(96);
      display: flex;
      align-items: center;
      text-align: left;
      gap: rem-calc(56);
    }
    
    .text {
      margin-bottom: rem-calc(32);

      @include breakpoint-up(tablet) {
        margin-bottom: 0;
        flex-grow: 1;
      }

      .cmp-text {
        color: $color-text-white;
        margin: 0;
        line-height: normal;
        overflow-x: visible;
        
        h1, h2, h3, h4, h5, h6 {
          font-size: rem-calc(28);
          line-height: 110%;
          text-transform: uppercase;

          @include breakpoint-up(tablet) {
            font-size: rem-calc(40);
          }
        }
      }
    }

    .title {
      .cmp-title {
        color: $color-text-white;
        margin: 0;
      }
    }
  }
}