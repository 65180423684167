.button {
  $this: &;
  
  .cmp-button {
    @include base-button();
    width: 100%;

    @include breakpoint-up(tablet) {
      width: auto;
    }
  }

  &--secondary {
    .cmp-button {
      @include secondary-button();
    }
  }

  &--tertiary {
    text-align: center;

    .cmp-button {
      @include tertiary-button();
    }
  }

  &--text-link {
    text-align: center;
    
    .cmp-button {
      @include text-link();
    }
  }

  &--inverted {
    .cmp-button {
      @include base-button(true);
    }

    &#{$this}--secondary {
      .cmp-button {
        @include secondary-button(true);
       }
    }

    &#{$this}--tertiary {
      .cmp-button {
        @include tertiary-button(true);
      }
    }

    &#{$this}--text-link {
      .cmp-button {
        @include text-link(true);
      }
    }
  }
}