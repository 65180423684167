.separator {
  
  // Desktop
  &.xx-small {
    height: rem-calc(16);
  }
  &.x-small {
    height: rem-calc(24);
  }
  &.small {
    height: rem-calc(32);
  }
  &.medium {
    height: rem-calc(48);
  }
  &.large {
    height: rem-calc(64);
  }
  &.x-large {
    height: rem-calc(120);
  }

  // Tablet
  @include breakpoint-down(md) {
    &.xx-small {
      height: rem-calc(16);
    }
    &.x-small {
      height: rem-calc(24);
    }
    &.small {
      height: rem-calc(32);
    }
    &.medium {
      height: rem-calc(32);
    }
    &.large {
      height: rem-calc(56);
    }
    &.x-large {
      height: rem-calc(80);
    }
  }

  // Mobile
  @include breakpoint-down(sm) {
    &.xx-small {
      height: rem-calc(8);
    }
    &.x-small {
      height: rem-calc(16);
    }
    &.small {
      height: rem-calc(24);
    }
    &.medium {
      height: rem-calc(32);
    }
    &.large {
      height: rem-calc(56);
    }
    &.x-large {
      height: rem-calc(56);
    }
  }
}
.cmp-separator__horizontal-rule {
  background-color: $color-background-white;
  border: none;
}
