.teaser.full-bleed {

  &.text-right {
    .cmp-teaser {
      flex-direction: row-reverse;
      @include breakpoint-down(tablet) {
        flex-direction: column-reverse;
      }
      &__content {
        width: 40%;
        margin: 0 auto;
        padding-left: spacing(12);
        padding-right: 0;

        @include breakpoint-down(tablet) {
          margin: 0;
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }

  .cmp-teaser {
    display: flex;
    width: 100%;
    gap: spacing(4);
    color: $color-text-primary;
    text-decoration: none;

    @include breakpoint-down(tablet) {
      width: 100%;
      flex-direction: column-reverse;
    }

    &__image {
      width: 50%;
      @include breakpoint-down(tablet) {
        width: 100%;
      }
    }

    &__content {
      justify-content: center;
      flex-direction: column;
      display: flex;
      width: 50%;
      padding-right: spacing(12);
      @include breakpoint-down(tablet) {
        padding-right: 0;
        width: 100%;
      }
    }

    &__title {
      font-family: 'Druk-SuperItalic';
      font-size: rem-calc(40);
      line-height: 110%;

      @include breakpoint-down(tablet) {
        font-size: rem-calc(28);
      }

      a {
        font-size: rem-calc(40);
        color: $color-text-primary;
        text-decoration: none;
        @include breakpoint-down(tablet) {
          font-size: rem-calc(28);
        }
      }
    }

    &__action-link {
      @include base-button();
      width: 100%;
      max-width: 327px;
      margin-bottom: 40px;
      margin-top: spacing(4);
      
      @include breakpoint-down(tablet) {
        max-width: 100%;
        width: -webkit-fill-available;
        width: -moz-available;
        margin-bottom: 35px;
      }
    }
  }

  &.cta-link {
    .cmp-teaser {
      &__action-link {
        @include tertiary-button();
      }
    }
  }
}
