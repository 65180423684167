.tabs.faq {
  .cmp-tabs {
      // matching left/right padding to .container.centered-content 
    padding: 0 spacing(2) 0;
    margin: 0;

    @include breakpoint-up(tablet) {
      padding-top: spacing(4);
    }
   

    &__dropdown-selector {
      display: flex;
      gap: spacing(1);
      align-items: center;
      background-color: transparent;
      border: none;
      font-size: rem-calc(14);
      line-height: 100%;
      font-weight: 600;
      @include icon($iconCaretDown, 14, 14);
      cursor: pointer;
      margin: 0 auto;
      
      @include breakpoint-up(tablet) {
        max-width: rem-calc(736);
      }

      &:after {
        transition: transform 0.3s ease;
      }
  
      &.expanded {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }
  
    &__tablist {
      display: none;
      margin: 0;
      border: none;
      margin-top: spacing(3);
      padding: spacing(3) 0;
      column-gap: spacing(4);
      row-gap: spacing(2);
  
      @include breakpoint-up(tablet) {
        max-width: rem-calc(736);
        margin-left: auto;
        margin-right: auto;
      }

      &.expanded {
        border-top: rem-calc(1) solid $color-border-black;
        border-bottom: rem-calc(1) solid $color-border-black;
        display: flex;
      }
    }
  
    &__tab {
      border-bottom: none;
      padding: 0;
      display: flex;
      gap: spacing(2);
      flex: 1 1 100%;
      align-items: center;
      color: $color-text-grey-5;
      height: auto;

      @include breakpoint-up(tablet) {
        flex-basis: 30%;
      }

      &:before {
        content: '';
        min-width: rem-calc(20); // this keeps the "bullet" from shrinking
        height: rem-calc(20);
        width: rem-calc(20);
        border-radius: 100%;
        border: rem-calc(1) solid $color-border-grey-5;
      }
  
      &.cmp-tabs__tab--active {
        color: $color-text-black;

        &:after {
          content: '';
          height: rem-calc(14);
          width: rem-calc(14);
          position: absolute;
          margin: spacing(.5);
          border-radius: 100%;
          background-color: $color-background-black;
          border-color: $color-border-black;
        }
      }
    }

    &__tabpanel {
      max-width: rem-calc(1120);
      margin: 0 auto;
      padding: 0;
  
      @include breakpoint-down(tablet) {
        max-width: rem-calc(640);
      }
    }
  }
}