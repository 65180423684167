
//== Font

$font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-body: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-title: 'Source Serif Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size: 16;
$font-height: 20px;

// Base Colors
$colors-black: #000000;
$colors-white: #ffffff;
$colors-blue: #00a7f4;
$colors-blue-10: #436eff;
$colors-grey: #fafafa;
$colors-grey-2: #f1f1f1;
$colors-grey-3: #d4d4d4;
$colors-grey-4: #9b9b9b;
$colors-grey-5: #6e6e6e;
$colors-grey-6: #505050;

//== Color
$color-background-black: $colors-black;
$color-background-white: $colors-white;
$color-background-grey: $colors-grey;
$color-background-grey-3: $colors-grey-3;
$color-background-grey-5: $colors-grey-5;
$color-background-grey-6: $colors-grey-6;
$color-border-black: $colors-black;
$color-border-white: $colors-white;
$color-border-blue: $colors-blue;
$color-border-grey: $colors-grey;
$color-border-grey-3: $colors-grey-3;
$color-border-grey-4: $colors-grey-4;
$color-border-grey-5: $colors-grey-5;
$color-border-grey-6: $colors-grey-6;
$color-border-blue-10: $colors-blue-10;
$color-text-black: $colors-black;
$color-text-white: $colors-white;
$color-text-grey: $colors-grey;
$color-text-grey-3: $colors-grey-3;
$color-text-grey-5: $colors-grey-5;
$color-text-grey-6: $colors-grey-6;


$color-text-primary: $colors-black;
$color-text-secondary: $colors-white;
$color-brand: $colors-blue;
$color-link: #2020e0;
$color-accent: #2954ff;
$color-accent-lighter: #436eff;
$color-accent-darker: #103be6;
$color-grey: rgb(169, 169, 169);
$color-shade-1: #eceff1;
$color-shade-2: #cfd8dc;
$color-shade-3: #607d8b;
$color-shade-4: #455a64;

$error: #d7373f;
$error-darker: #be1e26;
$success: #268e6c;
$success-darker: #0d7553;

$color-text: $colors-black;
$color-text-inverted: $colors-white;
$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);

// Breakpoints
$breakpoint-tablet: 769px; // 768 + 1 so that 768 is still tablet
$breakpoint-mobile: 577px;

$grid-breakpoints: (
  xs: 0,
  sm: $breakpoint-mobile,
  mobile: $breakpoint-mobile,
  md: $breakpoint-tablet,
  tablet: $breakpoint-tablet,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$spacing-base: 8;
