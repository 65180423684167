// Logical Properties regarding `float` are broken in Percy - ignoring them altogether

.nutritional-information {
  background: $color-background-grey;
  padding: spacing(4) spacing(3) spacing(2.5);

  &__row {
    font-size: rem-calc(14);
    line-height: rem-calc(26);
    overflow: hidden;

    a {
      color: $color-text-black;
    }

    &.column-1--bold .column1,
    &.column-2--bold .column2,
    &.column-3--bold .column3 {
      font-weight: bold;
      h3 {
        font-size: rem-calc(24);
        line-height: rem-calc(32);
      }
    }

    .column3 {
      float: right;
    }

    &.line--1px,
    &.line--2px,
    &.line--5px {
      margin-top: spacing(1);
      padding-top: spacing(1);
      border-top: rem-calc(1) solid $color-border-grey-4;
    }

    &.line--2px {
      border-width: rem-calc(2);
    }

    &.line--5px {
      border-width: rem-calc(5);
    }

    &:is(.row--header, .row--big) {
      font-size: rem-calc(18);
    }

    &.row--small {
      font-size: rem-calc(12);
    }
  }
}
